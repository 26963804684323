import payload_plugin_ALLXFGgbCP from "/home/runner/work/web/web/website/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/web/web/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/web/web/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/web/web/website/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/web/web/website/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web/web/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/web/web/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/runner/work/web/web/website/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/home/runner/work/web/web/website/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_pNVVgm7Ud2 from "/home/runner/work/web/web/website/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/web/web/website/.nuxt/vuefire-plugin.mjs";
import slideovers_X7whXyrIWR from "/home/runner/work/web/web/website/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/web/web/website/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/web/web/website/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_WLsn00x1qh from "/home/runner/work/web/web/website/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/home/runner/work/web/web/website/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/web/web/website/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/web/web/website/.nuxt/sentry-client-config.mjs";
import plugin_H2YfXjPOAw from "/home/runner/work/web/web/website/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/runner/work/web/web/website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/web/web/website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_OVoKJro5pc from "/home/runner/work/web/web/website/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import numberFormat_CZU1VKV9BH from "/home/runner/work/web/web/website/plugins/numberFormat.js";
import logger_client_NoN5FB4OWs from "/home/runner/work/web/web/website/plugins/logger.client.ts";
import datetimeFormat_K7GtNq2Psx from "/home/runner/work/web/web/website/plugins/datetimeFormat.js";
import process_links_vRJq1RAFux from "/home/runner/work/web/web/website/plugins/process-links.js";
import cookie_script_client_xs8lObEQLO from "/home/runner/work/web/web/website/plugins/cookie-script.client.ts";
import tapfiliate_client_WCRmUgF9sR from "/home/runner/work/web/web/website/plugins/tapfiliate.client.js";
export default [
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_ghbUAjaD3n,
  plugin_client_pNVVgm7Ud2,
  vuefire_plugin_8cq4FnzXKb,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_WLsn00x1qh,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_H2YfXjPOAw,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_client_OVoKJro5pc,
  numberFormat_CZU1VKV9BH,
  logger_client_NoN5FB4OWs,
  datetimeFormat_K7GtNq2Psx,
  process_links_vRJq1RAFux,
  cookie_script_client_xs8lObEQLO,
  tapfiliate_client_WCRmUgF9sR
]